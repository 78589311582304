<template>
    <div class="img-felling">
        <p class="title-feeling">{{ $t('serviceRender.titleFeeling1')}}</p>
        <p class="title-feeling">{{ $t('serviceRender.titleFeeling2')}}</p>
    </div>
    <div class="container">
            <div class="row">
                <div class="col-sm-6 width-feeling">
                    <img v-lazy="{src: require('@/assets/render/Q1F.webp')}" alt="" class="img-feeling">
                </div>
                <div class="col-sm-6 width-feeling">
                    <p class="detailTitleRender">{{ $t('serviceRender.detailTitle')}}</p>
                </div>
            </div>
        </div>
    <div class="container">
        <p class="assetup-service-render">{{ $t('serviceRender.titleServeiceRender')}}</p>
        <p class="catePricerate">{{ $t('serviceRender.catePricerate')}}</p>
        <a href="https://blog.assetup.co.th/product/service-render/" class="router-home"><p class="promotion-cl-render">{{ $t('serviceRender.buy')}}</p></a>
        <div class="container">
            <div class="row">
                <div class="col-sm-4">
                    <p class="title-border catePricerate1">{{ $t('serviceRender.catePricerate1') }}<br><span class="price-svp">{{ $t('serviceRender.price1') }}</span></p>
                    <img v-lazy="{src: require('@/assets/render/TYPE1.webp')}" alt="" class="img-catePricerate">
                </div>
                <div class="col-sm-4">
                    <p class="title-border catePricerate1">{{ $t('serviceRender.catePricerate2') }}<br><span class="price-svp">{{ $t('serviceRender.price2') }}</span></p>
                    <img v-lazy="{src: require('@/assets/render/IMG_5875.webp')}" alt="" class="img-catePricerate">
                </div>
                <div class="col-sm-4">
                    <p class="title-border catePricerate1">{{ $t('serviceRender.catePricerate3') }}<br><span class="price-svp">{{ $t('serviceRender.price3') }}</span></p>
                    <img v-lazy="{src: require('@/assets/render/IMG_5714.webp')}" alt="" class="img-catePricerate">
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <p class="title-border catePricerate1">{{ $t('serviceRender.catePricerate4') }}<br><span class="price-svp">{{ $t('serviceRender.price4') }}</span></p>
                    <img v-lazy="{src: require('@/assets/render/nordic_1_ren_03.webp')}" alt="" class="img-catePricerate">
                </div>
                <div class="col-sm-4">
                    <p class="title-border catePricerate1">{{ $t('serviceRender.catePricerate5') }}<br><span class="price-svp">{{ $t('serviceRender.price5') }}</span></p>
                    <img v-lazy="{src: require('@/assets/render/Havenna-Sarapee.webp')}" alt="" class="img-catePricerate">
                </div>
                <div class="col-sm-4">
                    <p class="title-border catePricerate1">{{ $t('serviceRender.catePricerate6') }} <br><span class="price-svp">{{ $t('serviceRender.price6') }}</span></p>
                    <img v-lazy="{src: require('@/assets/render/comingsoon.webp')}" alt="" class="img-catePricerate">
                </div>
            </div>
        </div>
        <p class="catePricerate example-img">{{ $t('serviceRender.ex')}}</p>
        <div class="row row-ex" v-for="(item, index) in items" :key="index">
            <div class="column column-ex"><img v-lazy="{src: item.image1}" alt="" onclick="openModal();currentSlide(1)" class="hover-shadow cursor img-example"></div>
            <div class="column column-ex"><img v-lazy="{src: item.image2}" alt="" onclick="openModal();currentSlide(2)" class="hover-shadow cursor img-example"></div>
            <div class="column column-ex"><img v-lazy="{src: item.image3}" alt="" onclick="openModal();currentSlide(3)" class="hover-shadow cursor img-example"></div>
            <div class="column column-ex"><img v-lazy="{src: item.image4}" alt="" onclick="openModal();currentSlide(4)" class="hover-shadow cursor img-example"></div>
            <div class="column column-ex"><img v-lazy="{src: item.image5}" alt="" onclick="openModal();currentSlide(5)" class="hover-shadow cursor img-example"></div>
            <div class="column column-ex"><img v-lazy="{src: item.image6}" alt="" onclick="openModal();currentSlide(6)" class="hover-shadow cursor img-example"></div>
            <div class="column column-ex"><img v-lazy="{src: item.image7}" alt="" onclick="openModal();currentSlide(7)" class="hover-shadow cursor img-example"></div>
            <div class="column column-ex"><img v-lazy="{src: item.image8}" alt="" onclick="openModal();currentSlide(8)" class="hover-shadow cursor img-example"></div>
            <div class="column column-ex"><img v-lazy="{src: item.image9}" alt="" onclick="openModal();currentSlide(9)" class="hover-shadow cursor img-example"></div>
            <div class="column column-ex"><img v-lazy="{src: item.image10}" alt="" onclick="openModal();currentSlide(10)" class="hover-shadow cursor img-example"></div>
            <div class="column column-ex"><img v-lazy="{src: item.image11}" alt="" onclick="openModal();currentSlide(11)" class="hover-shadow cursor img-example"></div>
            <div class="column column-ex"><img v-lazy="{src: item.image12}" alt="" onclick="openModal();currentSlide(12)" class="hover-shadow cursor img-example"></div>

            <div id="myModal" class="modal">
                <span class="close cursor" onclick="closeModal()">&times;</span>
                <div class="modal-content">
                    <div class="mySlides">
                        <div class="numbertext">1 / 12</div>
                        <img v-lazy="{src: item.image1}" alt="" style="width:100%">
                    </div>
                    <div class="mySlides">
                        <div class="numbertext">2 / 12</div>
                        <img v-lazy="{src: item.image2}" alt="" style="width:100%">
                    </div>
                    <div class="mySlides">
                        <div class="numbertext">3 / 12</div>
                        <img v-lazy="{src: item.image3}" alt="" style="width:100%">
                    </div>
                    <div class="mySlides">
                        <div class="numbertext">4 / 12</div>
                        <img v-lazy="{src: item.image4}" alt="" style="width:100%">
                    </div>
                    <div class="mySlides">
                        <div class="numbertext">5 / 12</div>
                        <img v-lazy="{src: item.image5}" alt="" style="width:100%">
                    </div>
                    <div class="mySlides">
                        <div class="numbertext">6 / 12</div>
                        <img v-lazy="{src: item.image6}" alt="" style="width:100%">
                    </div>
                    <div class="mySlides">
                        <div class="numbertext">7 / 12</div>
                        <img v-lazy="{src: item.image7}" alt="" style="width:100%">
                    </div>
                    <div class="mySlides">
                        <div class="numbertext">8 / 12</div>
                        <img v-lazy="{src: item.image8}" alt="" style="width:100%">
                    </div>
                    <div class="mySlides">
                        <div class="numbertext">9 / 12</div>
                        <img v-lazy="{src: item.image9}" alt="" style="width:100%">
                    </div>
                    <div class="mySlides">
                        <div class="numbertext">10 / 12</div>
                        <img v-lazy="{src: item.image10}" alt="" style="width:100%">
                    </div>
                    <div class="mySlides">
                        <div class="numbertext">11 / 12</div>
                        <img v-lazy="{src: item.image11}" alt="" style="width:100%">
                    </div>
                    <div class="mySlides">
                        <div class="numbertext">12 / 12</div>
                        <img v-lazy="{src: item.image12}" alt="" style="width:100%">
                    </div>
                    <a class="prev" onclick="plusSlides(-1)">&#10094;</a>
                    <a class="next" onclick="plusSlides(1)">&#10095;</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            items: [
                { 
                    image1: require('../assets/render/4CBEC8E7-7C27-47B3-B2C3-77DEE7E93020.webp'),
                    image2: require('../assets/render/Q2F.webp'),
                    image3: require('../assets/render/S__2170883.webp'),
                    image4: require('../assets/render/S__2170885.webp'),
                    image5: require('../assets/render/W1F.webp'),
                    image6: require('../assets/render/IMG_4927.webp'),
                    image7: require('../assets/render/IMG_4928.webp'),
                    image8: require('../assets/render/Q1F-_1_.webp'),
                    image9: require('../assets/render/0001-_1_.webp'),
                    image10: require('../assets/R1F.webp'),
                    image11: require('../assets/T1F.webp'),
                    image12: require('../assets/render/E1F (1).webp')
                },
            ]
        }
    }
}
</script>